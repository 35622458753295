.toggle-filter-settings {
  color: grey;
  cursor: pointer;
}

.toggle-filter-settings:hover {
  color: rgb(0, 0, 0);
}

.filter-details-footer {
  position: absolute;
  bottom: 0;
  background-color: #fff;
  width: 100%;
  left: 0;
  height: 80px;
  display: flex;
  align-items: center;
  padding: 20px;
  border-top: 4px solid #efedeb;
}
