.hidesmallslider {
	display: flex !important;
}

.labels {
	width: 120px;
}

@media (max-width: 1250px) {
	.hidesmallslider {
		display: none !important;
	}

	.labels {
		width: auto;
		min-width: 120px;
	}

}
