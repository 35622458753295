.navItem {
  min-width: 80px;
  max-width: 160px;
  /* border-radius: 6px 6px 0 0; */
  width: fit-content;
  padding: 0px 10px;
  color: #667085;
  box-sizing: content-box;
}

.activeNavItem {
  border-bottom: 2px solid #025876;
  color: #025876 !important;
}

.navItemLeftActiveBorder {
  color: #025876 !important;
}


.leadDetailsOffCanvas_Header {
  border-bottom: 4px solid #efedeb;
  background-color: #fff;
}

.leadOffCanvasBody {
  padding: 40px 70px;
  display: flex;
  max-height: 90vh;
  overflow-y: auto;
}

.cardRow {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 16px;
}

.cardBadge {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 8px 2px 10px;
  gap: 4px;
  background: #e6ece9;
  border-radius: 16px;
  font-weight: 600;
}



@media (min-width: 768px) {
  .card {
    max-width: 500px;
  }
}
