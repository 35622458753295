table {
  border-collapse: collapse;
  empty-cells: show;
}

td {
  position: relative;
  vertical-align: middle;
}

tr.strikeout td:before {
  content: " ";
  position: absolute;
  top: 45%;
  left: 0;
  border-bottom: 2px solid #111;
  width: 100%;
}

tr.strikeout td:after {
  content: "\00B7";
  font-size: 1px;
}
