.filter-item-container {
  border: 1px solid #dddddd00;
  position: relative;
  padding: 12px 20px 12px 8px;
  border-radius: 8px;
  cursor: pointer;
  
}

.filter-item-container:hover {
  border: 1px solid #dddddd;
}

.filter-item-container.selected {
  border: 1px solid #dddddd;
  background: #ffffff;
}

.filter-item-container.selected:hover {
  border: 1px solid rgb(57 174 207 / 76%) !important;
}

.filter-item-container.selected:after {
  position: absolute;
  width: 1px;
  height: 1rem;
  content: "";
  background-color: #dddddd;
  right: 50%;
  bottom: calc(-1rem - 1px);
  z-index: 1;
}

.filter-item-container-remove {
  color: #dadada
}

.filter-item-container-remove:hover {
  color: #fda8a8
}

.active-filter-box {
  border: 1px solid rgb(62 190 227 / 52%) !important;
  box-shadow: 0px 0px 8px 1px rgba(150, 188, 199, 1);
}

.filter-item-icon-col {
  // background: #96c97e;
  height: 39px;
  width: 39px;
  padding: 3px;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
