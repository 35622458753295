.modal-lg,
.modal-xl {
  --bs-modal-width: 75%;
}
.create-segment-modal .modal-content {
  max-width: 1300px;
}

.choice-disabled {
  filter: grayscale(1) opacity(0.2);
}
