.canvas-container {
  height: 35vh !important;
  position: relative;
}

@media (min-width: 768px) {
  .canvas-container {
    height: auto;
  }
}
