div.gmnoprint 
  button[title="Rita en figur"],
  button[title="Rita en cirkel"],
  button[title="Sluta rita"] {
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    cursor: pointer;
    margin-bottom: 15px !important;


}