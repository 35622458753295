.button-large {
  background: none;
  color: #000000;
  text-align: left;
  border: 1px solid rgba(221, 221, 221, 1);
  box-shadow: 0px 0px 5px 0px 0px 8px 1px rgba(45, 100, 80, 0) !important;
  padding: 18px;
}

.button-large:hover {
  border: 1px solid rgba(57, 174, 207, 0.76) !important;
  box-shadow: 0px 0px 8px 1px rgb(150, 188, 199) !important;
  background: #fff;
  color: #313131;
}

.btn-text {
  color: #666666;
  font-size: 13px;
}
