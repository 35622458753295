@import "assets/styles/_colors";

.menu-item {
  background: none;
  border: none;
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 8px;
  font-weight: 800;
  display: flex;
}

.menu-item:hover {
  background: $light-blue;
}

.menu-item-active {
  background: $text-primary;

  border-radius: 8px;
  border: 1px solid #3fbce200 !important;
}

.menu-item-active:hover {
  border: 1px solid $primary-color !important;
}

.menu-item-active .menu-item-icon {
  color: $primary-color;
}

.menu-item-active .menu-item-text {
  color: $light-blue;
  font-weight: 500;
}

.menu-item-active:hover .menu-item-text {
  color: $primary-color;
}

.menu-item-icon,
.menu-item-text {
  color: $blue-grey;
}

.customer-name {
  color: $blue-grey;
  margin-bottom: 5px;
}

.customer-logo {
  box-shadow: 0px 1px 3px rgba($dark-blue, 0.1),
    0px 1px 2px rgba($dark-blue, 0.06);
  border: 3px solid $blue-grey;
}

.logout-icon {
  color: $red-warning;
}

.logout-text {
  color: $medium-grey;
}

.logout-text:hover {
  color: $dark-blue;
}
