.navItem {
  border-bottom: 4px solid #bb7826;
}
.campaignDetailsOffCanvas_Header {
  /* border-bottom: 4px solid #3ebee3; */
  background-color: #fff;
}
.cardRow {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 16px;
  font-size: 30px;
  font-weight: 600;
}
.cardBadge {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 8px 2px 10px;
  gap: 4px;

  background: #e6ece9;
  border-radius: 16px;
  font-weight: 600;
}

.btn {
  border: slategray 1px solid;
}

.settingsCol {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: end;
  color: #adadad;
}

.settingsCol:hover {
  color: #e1e1e1;
}

.campaignChannelFooter {
  position: absolute;
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center; */
  bottom: 0;
  min-height: 80px;
  width: 100%;
  background-color: #ffffff;
  z-index: 10;
  border-top: 1px solid #d6d1ce;
  box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.1);
}
/* @media (min-width: 768px) {
  .card {
    max-width: 500px;
  }
} */
