.row{
display: flex;
flex-direction: row;
flex-wrap: nowrap;
justify-content: space-between;
align-items: center;
padding: 0px;
padding-top: 3px;
gap: 16px;
}

.badge{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 8px 2px 10px;
  gap: 4px;
  
  
  
  background: #E6ECE9;
  border-radius: 16px;
  font-weight: 600;

}
.modal{
width:80vw !important;
  height: 70vh !important;
  max-width:80vw !important;
  max-height: 70vh !important;
}


