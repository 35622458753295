$primary-color: #3fbde2;
$text-primary: #000000;
$background-primary: #ffffff;
$dark-blue: #1d3557;
$blue-grey: #64748b;
$light-blue: #b0e6fc;
$off-white: #f1f1f1;
$light-grey: #fcfcfc;
$medium-grey: #d2d2d2;
$orange-info: #ffab40ff;
$red-warning: #ff6b6bff;