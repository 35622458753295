.top-container {
  
  border-bottom: 1px solid #dddddd;
  background: #f9f9f9;
}

.segment-content-container {
  background: #f9f9f9;
  margin-top: 4.75rem;
}

.segment-content-container .menu {
  border-right: 1px solid #dddddd;
  padding: 32px;
}

.bottom-container {
  border-top: 1px solid #dddddd;
  background-color: rgba(239, 237, 235, 0.757) !important;
}

.btn-go-back {
  background: none;
  border: 1px solid #dddddd;
  border-radius: 4px;
  color: #b4b4b4;
}

.btn-go-back:hover {
  background: none;
  color: #878787;
  border: 1px solid #b8b8b8;
}

// @media (min-width: 1400px) {
//   .top-container {
//     width: calc(100% - 14.5rem);
//   }

//   .bottom-container {
//     width: calc(100% - 14.5rem);
//   }
// }
