.add-filter-info-box {
  background-color: rgba(239, 237, 235, 0.49) !important;
  border-radius: 8px;
  padding: 32px;
}

.segment-type-container {
  border: 1px solid #dddddd00;
  position: relative;
  padding: 11px 23px;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #dddddd;
  background: #ffffff;
}

.segment-type-container:after {
  position: absolute;
  width: 1px;
  height: 1rem;
  content: "";
  background-color: #dddddd;
  right: 50%;
  bottom: calc(-1rem - 1px);
  z-index: 1;
}

.sub-filter-heading-col {
  height: 66px;
  border-bottom: #dddddd 1px solid;
  display: flex;
  justify-content: space-between;
  padding: 17px 32px 17px 21px;
}

.add-filter-button {
  background: #ffffff;
  // border: 1px dashed rgba(2, 124, 193, 1);
  color: rgba(2, 124, 193, 1) !important;
  // padding: 19px 16px;
  width: 100%;
  border-radius: 8px;
}

// .add-filter-button:hover {
//   background: #ffffff;
//   color: rgb(0, 96, 151);
//   border: 1px solid rgba(2, 124, 193, 1);
//   // box-shadow: 0px 0px 5px #027cc1;
// }

//Range slider
.multi-range-slider {
  border: none;
  box-shadow: none;
}

.multi-range-slider .thumb::before {
  border: 1px solid #6c9384;
  margin: -6px -12px;
  box-shadow: none;
}

.multi-range-slider .bar-left,
.multi-range-slider .bar-right,
.multi-range-slider .bar-inner {
  box-shadow: none;
  border: none;
}

.multi-range-slider .bar-left,
.multi-range-slider .bar-right {
  background-color: #efedeb;
}

.multi-range-slider .bar-inner {
  background-color: #6c9384;
}

//Multirange with labels---------------------------
.multi-range-slider-black {
  border: none;
  box-shadow: none;
  background-color: #efedeb00;
  color: #2a2c2a;
}

.multi-range-slider-black .thumb::before {
  border: 1px solid #6c9384;
  margin: -6px -12px;
  box-shadow: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.multi-range-slider-black .bar-left,
.multi-range-slider-black .bar-right,
.multi-range-slider-black .bar-inner {
  box-shadow: none;
  border: none;
}

.multi-range-slider-black .bar-left,
.multi-range-slider-black .bar-right {
  background-color: #efedeb;
}

.multi-range-slider-black .bar-inner {
  background-color: rgb(57 174 207) !important;
}

.multi-range-slider-black .caption {
  bottom: -25px;
}

.multi-range-slider-black .thumb .caption:before {
  content: attr(data-value);
}

.multi-range-slider-black .thumb .caption * {
  background-color: rgba(0, 0, 255, 0);
  font-weight: 500;
  font-size: 16px;
  border-radius: 0px;
  color: #2a2c2a;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0);
}

.min-caption {
  margin-right: 10px;
}

.max-caption {
  margin-bottom: 110px;
  margin-left: 10px;
}

.select-button {
  padding: 16px 32px;
  background: #ffffff;
  border-radius: 8px;
}

.dropdown-item {
  padding: 10px 20px;
  border-bottom: 1px solid rgb(221, 221, 221);
  // border-radius: 4px;
  // margin-bottom: 10px;
  // box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
  //   0px 1px 2px rgba(16, 24, 40, 0.06);
  font-size: 15px;
  font-weight: 500;
  color: #757575;
}

.dropdown-item:hover {
  color: #ffffff;
  background-color: rgb(62, 190, 227);
  // border: 1px solid rgb(46 150 181);
  // box-shadow: 0px 1px 3px rgba(95, 245, 178, 0.1),
  //   0px 1px 2px rgba(84, 253, 152, 0.06);
}

.add-filter-type > svg {
  font-size: 35px;
  color: rgb(61 213 243);
  cursor: pointer;
}

.add-filter-type > svg:hover {
  color: rgb(45, 152, 173);
}

.trash-can {
  color: rgba(255, 107, 107, 0.253);
}
.trash-can:hover {
  color: #ff6b6bff;
}

.save-filtergroup {
  color: #b0e6fc;
}
.save-filtergroup:hover {
  color: #3fbde2;
}

.remove-filtertype {
  position: absolute;
  right: -0.9vw;
  top: 9px;
}
