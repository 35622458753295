//Stepscontainer
.top-row {
  height: 64px;
  border-bottom: 1px solid #d6d1ce;
  padding: 17px 48px;
}

.campaign-radio-box {
  border-radius: 8px;
  padding: 18px;
  height: 100%;
  cursor: pointer;
  position: relative;
  z-index: 1;

  //Change to 100%
}

.campaign-radio-box.selected-radio-button {
  border: 1px solid rgba(62, 190, 227, 1);
    color: #017397;
  background-color: #ffffff;
}
.campaign-radio-box.disabled-radio-button {
  border: 1px solid #e1e1e1;
  color: #bdbdbd;
  background-color: #ffffff;
}

.campaign-radio-box.not-selected-radio-button {
  border: 1px solid #dddddd;
  color: #979a97;
}

.not-selected-radio-button .radio-box-title {
  color: #000000;
}

//Campaign Select Table

.campaign-table {
  background: #ffffff;
  border: 1px solid #efedeb;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 8px;
}

.campaign-column-title {
  font-size: 12px;
  font-weight: 500;
  color: #667085;
}

.campaign-table > tbody {
  font-size: 14px;
  font-weight: 500;
  color: #101828;
}

//Prioritize

.score-row {
  gap: 8px;
}

.score-col {
  background: #ffffff;
  cursor: pointer;
  text-align: center;
  padding: 8px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  color: #d0d5dd;
  border-radius: 8px;
  font-weight: 500;
  font-size: 28px;
  line-height: 60px;
}

.score-col-selected {
  border: 1px solid #6c9384;
  color: #6c9384;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 28px;
  line-height: 60px;
}
.score-col-xs {
  background: #ffffff;
  cursor: pointer;
  text-align: center;
  padding: 4px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  color: #d0d5dd;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
}

.score-col-selected-xs {
  border: 1px solid #6c9384;
  color: #6c9384;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center
}

//Inputs
.campaign-input {
  padding: 10px 14px 10px 14px;
  color: #667085;
  font-size: 1rem;
  word-spacing: 15px;
  // background: #f9f9f9;
}

.campaign-form-label {
  font-size: 0.875rem;
  color: #6a6d6a;
  font-weight: 400;
}