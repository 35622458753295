.activeNavItem {
  border-bottom: 2px solid #025876;
  color: #025876 !important;
  
}

/* .activeNavItem:after {
  content: "";
  position: absolute;
  margin: -20px;
  width: 4px;
  height: 4px;
  border-radius: 50%; 
  box-shadow: 0 0 0 250px #bb7826;
} */

.activeSubNavItem {
  background: #3ebee3;
  color: #fff;
  /* border-radius: 6px 6px 0 0; */
}
.navItem {
  min-width: 80px;
  max-width: 100px;
  /* border-radius: 6px 6px 0 0; */
  width: fit-content;
  padding: 0px 10px;
  color: #667085;
  box-sizing: content-box;
}

@media (max-width: 1100px) {
  .navItem {
    padding: 0;
  }
}
