.table-container {
    //   margin: 0;
  }
  
  table-lg > thead > tr {
    height: 2.625rem;
  
  }
  
  tbody > tr {
    height: 3.375rem;
    line-height: 2.3rem;
    cursor: pointer;
  }
  
  td, th {
      padding: 0.5rem 1rem !important;
  }