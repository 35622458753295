.cardTot {
  position: relative;
  color: #fafafa;
  width: 100%;
  margin: 0;
  box-shadow: 3px 4px 5px rgba(115, 115, 115, 0.09),
    -4px 0px 5px rgba(115, 115, 115, 0.09);
  height: 100%;
  padding: 10px;
  border-radius: 14px;
  max-height: 26vh;
  text-align: right;
  border: none;
  line-height: 1.2;
}

.cardTot svg {
  position: absolute;
  height: 100px;
  width: 100px;
  left: 10px;
  bottom: 10px;
}

.heading {
  font-size: 55px;
  font-weight: 600;
  margin: 0;
  padding: 0;
  text-shadow: rgb(0 0 0 / 15%) 1px 1px;
}

.desc {
  margin-right: 0;
  margin-left: 0;
  font-size: 18px;
  font-weight: 200;
  text-align: right;
}
