
.card-container {
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 8px;
}

.card-container.bg-light {
  background-color: #fff !important;
}

.card-container.bg-secondary {
  background-color: #f1f1f1 !important;
  color: #ffffff;
  box-shadow: none;
}



//pris, knapp för nedladdning, vid klick kom till målgruppens flyout för export (tab) 
