.row{
display: flex;
flex-direction: row;
flex-wrap: nowrap;
justify-content: space-between;
align-items: baseline;
padding: 0px;
gap: 16px;
/* border-bottom: 3px solid #EAECF0; */
}

.badge{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 8px 2px 10px;
  gap: 4px;
  
  
  
  background: #E6ECE9;
  border-radius: 16px;
  font-weight: 600;

}
