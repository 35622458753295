// .sidebar-container {
//   height: 100vh;
//   border-right: 1px solid #dddddd;
//   max-width: 14.5rem;
// }

@import "/src/assets/styles/_colors";

/* Add this to your existing style.scss file or create a new CSS file */
.sidebar-toggle {
  background-color: $light-grey !important;
  position: absolute;
  top: 0;
  left: 0;
  margin: 10px;
  z-index: 101;
  width: 20px;
  width: 50px;
  color: rgb(64, 191, 227);
  border: 1px solid rgb(43 132 157 / 23%);
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.sidebar-container {
  transition: transform 0.3s ease;
}

.sidebar-container-medium {
  transition: transform 0.3s ease;
  height: 100vh;
  border-right: 1px solid #dddddd;
  max-width: 14.5rem;
}

.sidebar-container.open {
  transform: translateX(0);
  height: 100vh;
  border-right: 1px solid #dddddd;
  width: 50vw;
  z-index: 100;
  position: absolute;
  background: white;
}
