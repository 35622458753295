.campaign-item-container {
  border: 1px solid #dddddd00;
  position: relative;
  padding: 12px;
  border-radius: 8px;
  cursor: pointer;
}

.campaign-item-container:after {
  position: absolute;
  width: 1px;
  height: 1.5rem;
  content: "";
  right: 50%;
  bottom: calc(-1.5rem - 1px);
  z-index: 1;
}
.campaign-item-container:last-of-type:after {
  width: 0px;
}
@media (max-width: 900px) {
  .campaign-item-container:after {
    height: 0;
  }
}

.active-step {
  border: 1px solid #e7ad67;
  background-color: #fff6eb;
  color: #2d6450;
}

.done {
  border: 1px solid rgba(62, 190, 227, 1);
  color: #017397;
  background: #fff;
}

.active-step:after,
.done:after {
  background-color: #929292;
}

.done:hover {
  border: 1px solid #e7ad67;
  filter: grayscale(0) opacity(100%);
}

.inactive {
  border: 1px solid #dbdbdb;
  filter: grayscale(1) opacity(80%);
}

// .inactive:hover {
//   border: 1px solid #e7ad67;
//   filter: grayscale(0) opacity(100%);
// }

.inactive:after {
  background-color: #dddddd;
}

.done-check-icon {
  z-index: 10;
  position: absolute;
  right: -10px;
  top: -10px;
  background-color: #f9f9f9;
  border-radius: 50%;
}
