.navItem {
  border-bottom: 4px solid #6c9384;
}
.leadDetailsOffCanvas_Header {
  border-bottom: 4px solid #efedeb;
  background-color: #fff;
}

.leadOffCanvasBody {
  padding: 40px 70px;
  display: flex;
  max-height: 90vh;
  overflow-y: auto;
}

.cardRow {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 16px;
}
.cardBadge {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 8px 2px 10px;
  gap: 4px;
  background: #e6ece9;
  border-radius: 16px;
  font-weight: 600;
}



@media (min-width: 768px) {
  .card {
    max-width: 500px;
  }
}
