.login-container {
  margin: 0;
  padding: 0;
}

.login-container > .row {
  height: 100vh;
}

.login-img {
  height: 100%;
  object-fit: cover;
}

.forgot-password {
  color: #017397;
}

.forgot-password:hover {
  color: #11a3cf;
}