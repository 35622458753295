.OffCanvas {
  height: 60vh !important;
}

.tableSegment > table {
  height: 100%;
}

.tableSegment > tbody {
  display: block;
  height: 90%;
  overflow: auto;
}

.tableSegment > thead,
.tableSegment > tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.tableSegment > thead {
  width: calc(100% - 1em);
}