// .stepper-container {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   position: relative;

.stepper-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 0;

  .step-indicators {
    position: relative;
    display: flex;
    // flex-wrap: wrap;
    flex-wrap: nowrap;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-content: center;
    margin-bottom: 20px;
    padding-top: 20px;

    .step-bg {
      // width: 60px;
      width: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      z-index: 1;


      .step-circle {
        height: 30px;
        width: 30px;
        border: 1px solid rgb(62, 190, 227);
        background-color: #FFF;
        border-radius: 50%;
        margin: 0 5px;
        transition: background-color 0.3s;
        font-weight: 800;
        font-size: 16px;
        line-height: 1.5;
        z-index: 5;
        position: relative;
        display: flex;
        align-items: center;
        color: rgb(62, 190, 227);

        >span {
          // position: absolute;
          width: 100%;
          text-align: center;
        }

        &.active {
          background-color: rgb(62, 190, 227);
          color: white;
        }

        &.small {
          height: 12px;
          width: 12px;
        }
      }
    }
  }


  .progress-bar {
    width: 100%;
    height: 4px;
    background-color: #f0f0f0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    .progress {
      height: 100%;
      position: absolute;
      background-color: rgb(62, 190, 227);
      transition: width 0.3s;
    }
  }
}
