// .statistics-top-container {
//   border-bottom: 1px solid #dddddd;
// }

// .statistics-content-container {
//   position: relative;
//   height: calc(100vh - 4.75rem);
// }

.statistics-step-container {
  overflow-y: auto;
  overflow-x: hidden;
}

.statistics-bottom-container {
  width: 100%;
  height: 4.75rem;
  border-top: 1px solid #dddddd;
  background: rgba(239, 237, 235, 0.757) !important;
  position: absolute;
  bottom: 0;
}
