.campaign-top-container {
  height: 4.75rem;
  position: fixed;
  top: 0;
  right: 0;
  border-bottom: 1px solid #dddddd;
  background: #f9f9f9;
}
.campaign-top-container-smallScreen {
  height: 4.75rem;
  top: 0;
  right: 0;
  border-bottom: 1px solid #dddddd;
  background: #f9f9f9;
  align-items: center;
  padding: 12px;
  z-index: 1
  
}

.campaign-content-container {
  position: relative;
  height: calc(100vh - 4.75rem);
  // margin-top: 4.75rem !important;
  background: #f9f9f9;
  overflow: hidden;
}
.campaign-content-container .left {
  border-right: 1px solid #dddddd;
  

}
.campaign-step-container {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  

}

.campaign-bottom-container {
  width: 100%;
  height: 4.75rem;
  border-top: 1px solid #dddddd;
  // background: rgba(239, 237, 235, 0.757) !important;
  background: #f0f0f0 !important;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
}

@media (min-width: 1400px) {
  .campaign-top-container {
    width: calc(100% - 14.5rem);
  }
}
@media (max-width: 430px) {
  .campaign-top-container-smallScreen {
    padding-bottom: 10px;
    padding-left: 70px;
  }
}
@media (min-width: 431px) and (max-width: 768px) {
  .campaign-top-container-smallScreen {
    height: 4.75rem;
    padding-left: 20%;
  }
}
