@import "assets/styles/_colors.scss";

.campaign-channels-table>td {
  padding: 16px 24px !important;
}

.form-switch .form-check-input {
  width: 36px;
  height: 20px;
  cursor: pointer;
}

.big-switch .form-check-input {
  width: 60px;
  height: 30px;
  cursor: pointer;
}

.image-picker-container {
  position: absolute;
  user-select: none;
  width: 100%;
  top: calc(25%);
  left: 0;
  text-align: center;
  color: rgb(121 121 121);
  cursor: pointer;
}

.image-picker-container-disabled {
  position: absolute;
  user-select: none;
  width: 100%;
  top: calc(25%);
  left: 0;
  text-align: center;
  color: rgb(121 121 121);
  cursor: not-allowed;
}

.image-picker-container:hover {
  color: rgb(45, 45, 45);
}

.question-mark {
  color: #d9d9d9;
  cursor: pointer;
}

.question-mark:hover {
  color: #bcbcbc;
}

//Danger tooltip
.danger .tooltip-inner {
  color: #b42318;
  background-color: #fef3f2;
  max-width: 300px;
  border: 1px solid #b4231838;
}

.danger.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: #b42318;
}

//Warning tooltip
.warning .tooltip-inner {
  color: #b54708;
  background-color: #fffaeb;
  max-width: 400px;
  border: 1px solid #b4a71838;
}

.warning.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: #b54708;
}

//Success tooltip
.success .tooltip-inner {
  color: #49b418;
  background-color: #f7fef2;
  max-width: 300px;
  border: 1px solid #49b41838;
}

.success.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: #49b418;
}

//Secondary tooltip
.secondary .tooltip-inner {
  color: #344054;
  background-color: #f2f4f7;
  max-width: 400px;
  border: 1px solid #00000024;
}

.secondary.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: #344054;
}

//--------------

.campaign-statistics-btn {
  color: #017397;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
}

.campaign-statistics-btn:hover {
  color: #5195aa;
  text-decoration: underline;
}

.edit-settings-container {
  background-color: white;
  padding: 0 20px 20px 20px;
  border-radius: 8px;
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
}

.edit-row-active {
  background-color: rgb(213 213 213 / 10%);
  padding: 5px 0;
  align-items: center;
  border-radius: 4px;
  font-weight: 800;
}

.edit-row-active .form-control {
  font-size: 12px;
  font-weight: 500;
  border: 1px solid #00000033;
  cursor: pointer;
}

.edit-row-active .form-control:hover {
  border: 1px solid #03a9f4;
}

.edit-col-icon {
  color: #3ebee3;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 18px;
}

.edit-col-icon:hover {
  color: #2e8ea8;
}

.status-alert-container .alert {
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  background: white;
  border-right: none;
  border-bottom: none;
  border-top: none;
  padding: 10px;
  font-size: 12px;
  margin: 0;
  font-weight: 500;
}

.status-alert-container .alert-success {
  border-left: rgb(66, 161, 70) 6px solid;
  color: rgb(66 161 70 / 62%);
}

.status-alert-container .alert-warning {
  border-left: $orange-info 6px solid;
  color: $orange-info;
}
