.offcanvas{
    width:50% !important;
}  

.passwordRequirements {
    // display:none;
    background: #f1f1f1;
    color: #000;
    position: relative;
    padding: 20px;
    margin-top: 10px;
    border-radius: 10px;
    font-size: 14px;
  }
