/* CardDisplayTotal-------- */
.container-display-total {
  width: 12vw;
  height: 20vh;
}

.card-display-total {
  color: #fafafa;
  position: relative;
  height: 100%;
  width: 100%;
  padding: 10px;
  border-radius: 14px;
  box-shadow: rgb(115 115 115 / 9%) 3px 4px 5px,
    rgb(115 115 115 / 9%) -4px 0px 5px;
  line-height: 1;
  cursor: pointer;
}

.sum-row {
  text-align: right;
  justify-content: center;
}

.total-sum-text {
  font-size: 45px;
  font-weight: bold;
  margin: 0;
  padding: 0;
  text-shadow: rgb(0 0 0 / 15%) 1px 1px;
}

.sub-heading-text {
  margin-right: 0;
  margin-left: 0;
  font-size: 18px;
  line-height: 1.3;
  font-weight: 200;
  text-align: right;
}

.icon-display-total {
  position: absolute;
  height: 100px;
  width: 100px;
  left: 10px;
}

.detail-row {
  position: absolute;
  bottom: 5px;
  right: 10px;
  font-size: 12px;
  font-weight: 500;
}
