.draggable{
  display: flex;
  border: 2px solid black;
  justify-content: center;
  align-items: center;
  cursor:grab;
  padding: 5px;
  border-radius: 5px;
}

.arrow{
  display: none;
  z-index: 10;
}

.draggable:hover .arrow{
  display: flex;
}

.draggable:hover{
  padding: 10px;
}