
.tab1 {
    border: 1px solid #ccc;
    padding: 10px 20px;
    background-color: #fff;
    cursor: pointer;
    margin: 5px;
    
    &:hover {
      background-color: #f0f0f0;
    }
    
    &.active {
      background-color: #007bff;
      color: #fff;
    }
  }
  





  

