.offcanvas{
  width:30% !important;

}

@media (min-width: 600px) {
.offcanvas{

  min-width: 450px;
}
}

.label {
  font-size: 15px;
  padding-bottom: 10px;
}
