.label {
  font-size: 15px;
  padding-bottom: 10px;
  font-weight: "semi-bold";
}
.offcanvas {
  @media (max-width: 767px) {
    width: 100% !important;
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    width: 50% !important;
  }

  @media (min-width: 1200px) {
    width: 30% !important;
  }
}
.line {
  height: 1px;
  color: gray;
  background-color: gray;
}

.removeofferbox {
  background: #e27373;
  border-radius: 2px;
  cursor: pointer;
  padding: 3px;
  color: #fff;
  font-weight: 500;
  margin: 0px 3px;
}

.removeofferbox:hover {
  background: #ff4949;
}
