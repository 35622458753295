//vehicle-pagination
.vehicle-pagination {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.vehicle-pagination .pages {
  display: flex;
}

.vehicle-pagination > .prev, .vehicle-pagination > .next {
  width: 33px;
  height: 33px;
  background: white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #bdbdbd;
  margin: 0 20px;
  cursor: pointer;
}

.vehicle-pagination .page {
  width: 33px;
  height: 33px;
  background: white;
  
  border-radius: 8px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #bdbdbd;
  margin: 0 20px;
  cursor: pointer;
}

.vehicle-pagination div.active {
  background-color: rgba(230, 236, 233, 0.49);;
  color: #6C9384;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
}

.vehicle-pagination div.vehicle-pagination-arrow {
  font-size: 24px;
  color: #6C9384;
}

.vehicle-pagination div.vehicle-pagination-arrow.inactive {
  opacity: 0.7;
  cursor: not-allowed;
}
