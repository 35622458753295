$--bs-accordion-active-bg: rgba(0, 0, 0, 0);

.name-col {
  border: 1px solid #dddddd;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.name-col:hover {
  border: 1px solid #aaaaaa;
}

// .accordion {
//   margin: 0;
// }

// .accordion-item {
//   border: 1px dashed rgba(2, 124, 193, 1);
// }

// .accordion-item:hover {
//   box-shadow: 0px 0px 5px #027cc1;
// }


