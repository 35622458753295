.overviewInfoBox {
}

.overviewCreateButton {
  padding: 19px 0px 19px 16px;
  background: #ffffff;
  border: 2px dashed #027cc1;
  border-radius: 8px;
  box-shadow: 0px 0px 8px 1px #96bcc7;
  display: flex;
  align-items: center;
  color: #027cc1;
  cursor: pointer;
}

.overviewCreateButton:hover {
    box-shadow: 0px 0px 8px 2px #96bcc7;
    color: #5b9bbf;
    border: 2px dashed #96bcc7;
}
