.btn {
	border: 3px solid #017397;
	color: #017397;
	border-radius: 15px;
}

.btn:hover {
	background-color: #017397;
	border: 3px solid #FFF;
	color: #FFF;
}

.btn-select {
	background-color: rgba(1, 115, 151, 0.8);
	border: 3px solid  rgba(1, 115, 151, 0.5);
	
	color: #FFF;

	border-radius: 15px;
}

