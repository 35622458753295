.offcanvas{
  width:50% !important;


  
  
}
.label {
  font-size: 15px;
  padding-bottom: 10px;
}
.title {
  border: solid;
  border-radius: 10px;
  padding: 5px;
  background-color: #017397;
  color: white;
  font-size: 10px;
  
}

.container {
  // display: flex;
  justify-content: start;
  align-items: center;
  height: 20vh;
}
.input {
  width: 200px;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 16px;
  
}

.button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 3px;
  font-size: 16px;
  cursor: pointer;
}

.button:hover {
  background-color: #0056b3;
}