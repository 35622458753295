@import "~bootstrap/scss/bootstrap.scss";
@import "_colors";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Onest:wght@100..900&display=swap");

//?General css
body {
  font-family: "Onest", sans-serif;
  color: $text-primary;
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
  color: unset;
}

a:hover {
  color: unset;
}

.main-content {
  min-height: 100vh;
  position: relative;
  background: $off-white;
  overflow: hidden auto;
  max-height: 100vh;
}

.container {
  max-width: none;
  padding-left: 5vw;
  padding-right: 5vw;
}

//?Font Weights-------------------------------------------
.regular {
  font-weight: normal;
}

.semi-bold {
  font-weight: 600;
}

.bold {
  font-weight: bold;
}

//?Font Sizes---------------------------------------------

.display-2xl {
  font-size: 4.5rem;
  line-height: 5.625rem;
}

.display-xl {
  font-size: 3.75rem;
  line-height: 4.5rem;
}

.display-lg {
  font-size: 2.25rem;
  line-height: 2.75rem;
}

.display-md {
  font-size: 1.875rem;
  line-height: 2.75rem;
}

.display-sm {
  font-size: 1.5rem;
  line-height: 2.375rem;
}

.display-xs {
  font-size: 1.25rem;
  line-height: 1.875rem;
}

.text-xl {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-lg {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-md {
  font-size: 0.875rem;
  line-height: 1.25rem;
  // color: #6a6d6a;
}

.text-sm {
  font-size: 0.75rem;
  line-height: 1.125rem;
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1.125rem;
}

//?Forms

.form-check-input {
  cursor: pointer;
}

.form-check-input:checked {
  background-color: $primary-color;
  border-color: $off-white !important;
}

.form-check-input:focus {
  box-shadow: none !important;
  border-color: $off-white !important;
}

.form-control {
  border: 1px solid $off-white;
  box-shadow: 0px 1px 2px rgba($dark-blue, 0.05);
  border-radius: 8px;
}

.create-campaign-form .form-control {
  padding: 12px;
}

.was-validated .prevent-validation.form-control:valid,
.prevent-validation.form-control.is-valid {
  padding: 0.375rem 0.75rem;
  border-color: $light-blue;
  background-image: none;
}

.input-group-text {
  background-color: $background-primary;
  border-color: $off-white;
  color: $blue-grey;
}

.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(
    .form-floating
  ) {
  border-right: none;
}

.select__contol--menu-is-open,
.select__contol--is-focused,
.select__control:focus {
  z-index: 2;
}

.spinner-border {
  border-color: $dark-blue;
  border-right-color: transparent;
}

.slow-spinner {
  --bs-spinner-animation-speed: 1.6s;
}

//?Dropdowns
.dropdown-menu.show {
  max-height: none;
  padding: 0px;
  border: 1px solid $off-white;
  box-shadow: 0px 1px 2px rgba($dark-blue, 0.16) !important;
}

//?Accordion
.accordion-button {
  padding: 19px 16px;
  color: $light-blue;
  font-weight: 500;
}

.accordion-button::after {
  display: none;
}

.accordion-button:not(.collapsed) {
  background-color: rgba(0, 0, 0, 0) !important;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0) !important ;
  font-weight: 700;
}

.accordion-button:focus {
  border-color: rgba(0, 0, 0, 0);
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0) !important ;
}

//?Table

.table-responsive {
  border-radius: 8px;
  background: $light-grey;
}

tbody > tr {
  height: 3.375rem;
  line-height: 2.3rem;
  cursor: pointer;
  border-color: $off-white;
}

tr > th {
  color: $blue-grey !important;
  background-color: $light-grey !important;
  font-weight: 500;
}

tr > td {
  color: $dark-blue !important;
  font-weight: 600;
  font-size: 14px;
}

tr > td > span {
  color: $blue-grey;
  font-weight: 400;
  line-height: 16px;
  margin: 0;
}

tr > td > .numeral {
  color: $text-primary;
  font-weight: 500;
  font-size: 14px;
}

.table-dates {
  color: $blue-grey;
  font-weight: 400;
  line-height: 16px;
  margin: 0;
}

.progress {
  height: 0.5rem;
}

//?Tooltip
.tooltip .tooltiptext {
  visibility: hidden;
  width: 100%;
  background-color: $off-white;
  color: $blue-grey;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 10;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

//?Buttons

//Bootrstrap buttons-----------
.btn {
  font-weight: 600;
}
.btn-success,
.btn-info {
  background: $primary-color !important;
  color: $background-primary;
  border: 1px solid $primary-color !important;
}

.btn-success:hover,
.btn-info:hover {
  background: $light-blue !important;
  color: $primary-color;
  border: 1px solid $primary-color !important;
}

.btn-warning {
  background: $orange-info;
  color: $background-primary;
  border: 1px solid $orange-info;
}

.btn-warning:hover {
  background: $off-white;
  color: $orange-info;
  border: 1px solid $orange-info;
}

.btn-danger {
  background: $red-warning !important;
  border: 1px solid $red-warning !important;
}

.btn-danger:hover {
  background: rgba($red-warning, 0.2) !important;
  color: $red-warning !important;
}

.btn-outline-dark {
  border: 1px solid $blue-grey;
  box-shadow: 0px 1px 2px rgba($dark-blue, 0.05);
}

.btn-outline-secondary {
  border: none;
  background: $background-primary;
  box-shadow: rgba($dark-blue, 0.1) 0px 0px 5px 0px,
    rgba($dark-blue, 0.1) 0px 0px 1px 0px;
  border-radius: 8px;
}

//Custom buttons--------------

.filter-button {
  background-color: $background-primary;
  border: 1px solid $off-white;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $dark-blue;
  box-shadow: 0px 1px 2px rgba($dark-blue, 0.05);
  border-radius: 8px;
  padding: 10px 16px;
}
.filter-indicator {
  position: absolute;
  top: 3px;
  right: 5px;
  font-weight: 600;
  font-size: 10px;
  padding: 3px;
  width: 15px;
  height: 15px;
  background: $light-blue;
  color: $primary-color;
  border: 1px solid;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-dropdown {
  position: absolute;
  background: $background-primary;
  z-index: 100;
  padding: 20px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid $off-white;
  box-shadow: 0px 1px 3px rgba($dark-blue, 0.1),
    0px 1px 2px rgba($dark-blue, 0.06);
  margin-top: 5px;
}

.delete-button {
  border: none;
  background: none;
  color: $blue-grey;
}

.delete-button:hover {
  color: $red-warning;
}

.btn-outline-info {
  border: none;
  color: $dark-blue;
  background-color: $background-primary;
  border: 1px solid $off-white;
  box-shadow: 0px 1px 2px rgba($dark-blue, 0.05);
}

.btn-outline-info:hover,
.btn-outline-info:active,
.btn-outline-info:focus-visible {
  background-color: $light-blue !important;
  border: none !important;
  border-color: rgba($dark-blue, 0);
  color: $dark-blue !important;
}

.edit-button {
  border: none;
  background: none;
  color: $blue-grey;
}

.edit-button:hover {
  color: $primary-color;
}

.btn-check:checked + .btn {
  background-color: $primary-color !important;
  font-weight: 600;
  border-color: $primary-color !important;
}

.dark-toggle-button {
  text-align: center;
  display: flex;
  align-items: center;
  border: none;
  margin: 5px;
}

.operator-toggle-button {
  text-align: center;
  display: flex;
  align-items: center;
  border: none;
}

.btn-check:checked + .dark-toggle-button {
  background-color: $primary-color;
  font-weight: 600;
  box-shadow: rgba($text-primary, 0.1) 0px 0px 5px 0px,
    rgba($text-primary, 0.1) 0px 0px 1px 0px;
  // border-color: #2d6450;
  border: none;
}

.btn-check + .btn:hover {
  background-color: $primary-color;
  color: $background-primary;
}

.btn-check + .operator-toggle-button:hover {
  background-color: $primary-color;
}

.btn-group > .btn-light,
.btn-group-vertical > .btn-light {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  border: none;
  color: $blue-grey;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.date-picker-group > .btn-light {
  background-color: $light-blue;
  color: $primary-color;
  border: 1px solid $primary-color;
  font-weight: 400;
}

.date-picker-group > .btn-light:hover {
  background-color: $primary-color;
  color: $light-blue;
}

.date-picker-group > .active.btn-light {
  background-color: $primary-color !important;
  color: $background-primary !important;
  border: 1px solid $primary-color !important;
  font-weight: 600;
}

.btn-group > .active.btn-light,
.btn-group-vertical > .active.btn-light {
  background-color: $background-primary;
  color: $dark-blue;
  font-weight: 700;
  border: 1px solid $off-white;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.1),
    0px 1px 1px rgba(16, 24, 40, 0.1);
}

.btn-group-vertical > .active.btn-light {
  color: $primary-color;
}

.btn:disabled {
  color: $off-white;
}

.offcanvas-header .btn-close {
  z-index: 100;
}

//?Pagination

.pagination {
  --bs-pagination-active-bg: $primary-color;
  --bs-pagination-active-border-color: $primary-color;
  z-index: 0;
}

.pagination.pagination-sm {
  margin: 0 20px 10px;
}

.page-link {
  border-radius: 3px;
}

.page-item:not(:first-child) .page-link {
  margin-left: 3px;
}

.page-link {
  color: $light-blue;
  border: 1px solid #f3f3f3;
  box-shadow: 0px 1px 2px rgba($dark-blue, 0.05);
}

.page-link:hover,
.page-link:focus {
  color: $primary-color;
  border: 1px solid $light-blue;
  background-color: $light-grey;
}

.page-link:focus {
  box-shadow: 0 0 0 0.25rem $light-blue;
}

.big-table-pagination .active > .page-link {
  padding: 0;
}

.pagination .form-control {
  width: 45px;
  color: $primary-color;
  border: 1px solid $primary-color;
  background: $light-blue;
  height: 100%;
}

.page-count {
  color: $blue-grey;
  margin-bottom: 10px;
}

//?OffCanvas

//*Setting offcanvas background image for social media campaign. Example for linkedin
.offcanvas.offcanvas-end {
  width: 80vw;
  background-color: $off-white;
}

.offcanvas.offcanvas-end .linkedin-container {
  background-image: url("https://static.vecteezy.com/system/resources/previews/018/930/587/original/linkedin-logo-linkedin-icon-transparent-free-png.png");
  background-color: $off-white;
  background-blend-mode: overlay;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.offcanvas.offcanvas-end .meta-container {
  background-image: url("https://freelogopng.com/images/all_img/1664035778meta-icon-png.png");
  background-color: $off-white;
  background-blend-mode: screen;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40%;
}

.offcanvas.offcanvas-end .googleads-container {
  background-image: url("https://freelogopng.com/images/all_img/1680369375google-ads-icon-png.png");
  background-color: $off-white;
  background-blend-mode: screen;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40%;
}

.offcanvas.offcanvas-end .tiktok-container {
  background-image: url("https://freelogopng.com/images/all_img/1655890827tiktok-icon-black-png.png");
  background-color: $off-white;
  background-blend-mode: screen;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40%;
}

.offcanvas.offcanvas-end .ai-segment {
  background-image: url("https://static.vecteezy.com/system/resources/previews/021/358/803/original/artificial-intelligence-icon-element-design-illustration-ai-technology-and-cyber-icon-element-futuristic-technology-service-and-communication-artificial-intelligence-concept-png.png");
  background-color: $off-white;
  background-blend-mode: screen;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40%;
}

.offcanvas {
  background-color: $off-white;
}

@media (max-width: 767.98px) {
  .offcanvas.offcanvas-end {
    width: 100vw !important;
  }
}

.offcanvas-body {
  padding: 0;
  overflow-x: hidden;
  height: 100%;
}

.lead-activity-offcanvas {
  width: 40vw !important;
  z-index: 1047;
  background-color: $background-primary !important;
}

//?Multiselect
.css-1nmdiq5-menu {
  z-index: 10 !important;
}

.css-1p3m7a8-multiValue,
.selected-filters {
  background-color: $light-blue !important;
  color: $primary-color;
  border: 1px solid $primary-color;
  padding: 5px;
  font-size: 18px;
  font-weight: 500;
  border-radius: 8px !important;
}

.css-wsp0cs-MultiValueGeneric,
.css-9jq23d {
  color: $primary-color !important;
}

.css-13cymwt-control {
  padding: 5px;
  border-color: $off-white !important;
  min-height: 58px !important;
  background-color: $light-grey !important;
}

.whiteBg .css-13cymwt-control {
  background-color: $background-primary !important;
}

.targeting > .css-13cymwt-control {
  background-color: $background-primary !important;
}

.visual-select {
  font-size: 12px;
}

.visual-select > .css-13cymwt-control,
.visual-select > .css-t3ipsp-control {
  padding: 0;
  min-height: 0 !important;
  font-size: 12px;
}
.visual-select .css-1fdsijx-ValueContainer > .css-1dimb5e-singleValue {
  background-color: #6c938400;
  padding: 5px 8px;
  border-radius: 4px;
  color: $background-primary;
}

.css-t3ipsp-control {
  min-height: 58px !important;
  box-shadow: 0 0 0 1px $primary-color !important;
  border-color: $primary-color !important;
}

.css-t3ipsp-control:hover {
  border-color: $primary-color !important;
}

//?VerticalTimeLine

.vertical-timeline {
  width: 100% !important;
  margin: 0 !important;
}

@media only screen and (min-width: 1170px) {
  .vertical-timeline-element {
    margin: 0 !important;
  }
}

.vertical-timeline::before {
  border-left: dashed 4px $light-blue;
  background: rgba($dark-blue, 0) !important;
  top: unset !important;
}

.vertical-timeline-element-content {
  background: rgba($dark-blue, 0) !important;
  color: $dark-blue;
  padding: 1em 0 !important;
  -webkit-box-shadow: 0px 1px 3px 0px rgba($dark-blue, 0) !important;
  border-radius: 0px 8px 8px 8px;
  box-shadow: 0px 1px 3px 0px rgba($dark-blue, 0) !important;
}

.vertical-timeline-element-content .lead-timeline-content {
  position: relative;
  color: $dark-blue;
  background: $background-primary;
  padding: 1em;
  -webkit-box-shadow: 0px 1px 2px 0px rgba($dark-blue, 0.06),
    0px 1px 3px 0px rgba($dark-blue, 0.1) !important;
  border: 1px solid $off-white;
  border-radius: 0px 8px 8px 8px;
  box-shadow: 0px 1px 2px 0px rgba($dark-blue, 0.06),
    0px 1px 3px 0px rgba($dark-blue, 0.1) !important;
}

//?Badge
.badge {
  font-weight: 500;
  padding: 6px 8px;
  font-size: 11px;
}

.badge.bg-warning {
  color: $orange-info;
  background-color: rgba($orange-info, 0.09) !important;
}

.badge.bg-success {
  color: $primary-color;
  background-color: $light-blue !important;
}

.badge.bg-info {
  color: $primary-color;
  background-color: $light-blue !important;
}

.badge.bg-danger {
  color: $red-warning;
  background-color: rgba($red-warning, 0.09) !important;
}

.badge.bg-secondary {
  color: $dark-blue;
  background-color: $off-white !important;
}

//?Modal

.image-picker-modal .modal-content {
  max-width: none;
}

.modal-content {
  margin: auto;
  max-width: 1100px;
  border: 2px solid $blue-grey;
}

//?Device preview

.marvel-device .screen {
  border-radius: 5px !important;
  box-shadow: 0 0 0 1px $blue-grey !important;
}

//?Slider

.slider-opposite .single-thumb .range-slider__thumb[data-lower] {
  width: 24px !important;
  background: $background-primary !important;
  border: 1px solid $primary-color !important;
}

.slider-opposite .range-slider .range-slider__thumb {
  background: none !important;
  border: none !important;
  width: 0 !important;
}

/* Range Slider as a Single Thumb Slider */
.single-thumb .range-slider__thumb[data-lower] {
  width: 0;
  background: rgba($dark-blue, 0.06) !important;
  border: none !important;
}
.single-thumb .range-slider__range {
  border-radius: 6px;
}

.range-slider .range-slider__range {
  background: $primary-color;
}

.range-slider .range-slider__thumb {
  background: $background-primary !important;
  border: 1px solid $primary-color;
}

.max-width-none {
  max-width: none !important;
}
.max-height-none {
  max-height: none !important;
}

.threshold-slider .range-slider .range-slider__thumb {
  border: 1px solid $primary-color;
}

.threshold-slider .range-slider .range-slider__range {
  background: $primary-color;
}
